import PricepointStructureComponent from "@/components/inventory/pricePoint/pricePointStructure/PricePointStructure.component";
import { policyList } from "@/enums/permissions";
import { Location } from "@/interfaces/location";
import { defaultPriceGroup, PriceGroups } from "@/interfaces/priceGroups";
import { PricingRule } from "@/interfaces/product";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { permissionService } from "@/services/permission.service";
import { priceGroupService } from "@/services/priceGroup.service";
import { sharingService } from "@/services/sharing.service";
import { PageNavAction } from "@/types/types";
import { CallbackPromise } from "@/types/types";
import { BooleanCheck } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PriceGroupsForm.template.vue";
const namespace = "AuthModule";

@Component({
  mixins: [Template],
  components: {
    PricepointStructureComponent
  }
})
export default class PriceGroupsFormComponent extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("user", { namespace })
  public user!: User;
  public isModifyAblePriceGroup: boolean = true;
  public model: PriceGroups = cloneDeep(defaultPriceGroup);
  public isEditing = false;
  public loading = false;
  public isOnlyPriceGroup!: boolean;
  public isLocationLoading = false;
  public num: number = 0;
  public priceGroupSharing = false;
  public isCurrentLocationSelected = false;

  public showLocation = true;
  public showCurrentLocation = false;
  public selectedLocation: string | number | null = "";
  public locations: Location[] = [];
  public priceGroupId!: number;

  public payloadObj = {
    source: "PRICING_GROUP",
    price_points: [
      {
        location_id: 1,
        code: "",
        is_active: true,
        rules: []
      }
    ],
    // sending price_group as payload just bcz other api's need prigroup for saving the price groups. This is not a required in payload.
    price_group: cloneDeep(defaultPriceGroup)
  };
  @Action("setCurrentLocation", { namespace })
  private setCurrentLocation!: CallbackPromise<any>;
  public cancel() {
    this.loading = true;
    this.$router.back();

    this.model = cloneDeep(defaultPriceGroup);
  }

  public emitPricing(data: PricingRule[]) {
    if (data.length) {
      for (const rules of data) {
        if (rules.price_breaks && rules.price_breaks.length) {
          rules.price_breaks!.map(item => {
            item.price = Number(item.price.toFixed(2));
            if (item.pre_excise) {
              item.pre_excise = Number(item.pre_excise.toFixed(2));
            }
          });
        }
      }
    }
    this.model.rules = cloneDeep(data);
  }

  public async save() {
    this.loading = true;

    const isValid = await this.$validator.validateAll();
    if (isValid) {
      this.model.group_type = "PRICING_GROUP";
      if (this.$route.name === "price-groups-add") {
        const modelCopy = cloneDeep(this.model);
        modelCopy.rules = [];
        // changing argument from modelCopy to this.payloadObj just to set the payload type in updatePriceRuleSet
        const responses = await priceGroupService.saveNewPriceGroup(
          this.payloadObj
        );
        await this.update(responses.uid);
      } else {
        // changing argument from modelCopy to this.payloadObj just to set the payload type in updatePriceRuleSet api call function
        const response = await priceGroupService.saveNewPriceGroup(
          this.payloadObj
        );
        if (response) {
          this.showMessage();
          this.cancel();
        }
      }
    }
    this.loading = false;
  }
  public async update(id?: string) {
    this.loading = true;
    if (!this.hasPermission(policyList.modifyPriceGroup)) {
      this.cancel();
      return;
    }
    const isValid = await this.$validator.validateAll();
    if (isValid) {
      this.model.group_type = "PRICING_GROUP";
      this.payloadObj.source = this.model.group_type;
      await priceGroupService
        .updatePriceGroup(this.model, this.$route.params.id || id!)
        .then(response => {
          this.num = response.id;
        });
      this.model.pricing_group_id = this.num;
      this.payloadObj.price_points.forEach(
        item => (item.code = `PRICING_GROUP_${this.num}`)
      );
      await priceGroupService
        .updatePriceRuleSet(
          this.$route.params.id || id!,
          this.payloadObj,
          this.num
        )
        .then(this.showMessage);
      this.cancel();
    }
    this.loading = false;
  }

  public showMessage() {
    messagesService.renderSuccessMessage(
      this.$route.name === "price-groups-edit"
        ? "price_groups_updated"
        : "price_groups_saved"
    );
  }

  public async onChangingLocation() {
    this.isCurrentLocationSelected = false;
    if (
      this.selectedLocation &&
      Number(this.selectedLocation) !== Number(this.currentLocation.id)
    ) {
      this.isCurrentLocationSelected = true;
    }
    if (this.$route.name === "price-groups-edit") {
      const locationSelected = this.locations.find(
        location => Number(location.id) === Number(this.selectedLocation)
      );

      EventBus.$emit(
        "onChangePriceGroupLocation",
        String(this.selectedLocation),
        locationSelected
      );
      this.isLocationLoading = true;
      const priceGroup = await priceGroupService.getPriceGroupById(
        this.$route.params.id,
        String(this.selectedLocation)
      );
      this.model = priceGroup;
      this.payloadObj.source = this.model.group_type;
      // pushing the rules to a particular location as per new payload requirement
      if (
        !this.payloadObj.price_points[
          this.currentLocationIndex(Number(this.selectedLocation))
        ]
      ) {
        this.payloadObj.price_points.push({
          location_id: Number(this.selectedLocation),
          code: `PRICING_GROUP_${
            this.model.pricing_group_id
              ? this.model.pricing_group_id
              : this.priceGroupId
          }`,
          is_active: true,
          // @ts-ignore
          rules: this.model.rules
        });
      }
      this.isLocationLoading = false;
    }
    // for adding rules to different location while creating new price group using below if condition
    if (this.$route.name === "price-groups-add") {
      const locationSelected = this.locations.find(
        location => Number(location.id) === Number(this.selectedLocation)
      );
      EventBus.$emit(
        "onChangePriceGroupLocation",
        String(this.selectedLocation),
        locationSelected
      );
      this.isLocationLoading = true;
      this.payloadObj.source = "PRICING_GROUP";
      if (
        !this.payloadObj.price_points[
          this.currentLocationIndex(Number(this.selectedLocation))
        ]
      ) {
        this.payloadObj.price_points.push({
          location_id: Number(this.selectedLocation),
          code: `PRICING_GROUP_${this.model.pricing_group_id}`,
          is_active: true,
          // @ts-ignore
          rules: this.model.rules
        });
      }
      this.isLocationLoading = false;
    }
  }

  public currentLocationIndex(locationSelectedId: number) {
    return this.payloadObj.price_points.findIndex(
      data => data.location_id === locationSelectedId
    );
  }

  protected created() {
    permissionService.getUserLocations(this.user.id).then(locations => {
      this.locations = locations;
      const locationId = +this.user.settings.current_location_id!;
      if (this.currentLocation && +this.currentLocation.id! !== locationId) {
        this.setCurrentLocation(
          this.locations.find(
            (location: Location) => +location.id! === +locationId!
          )
        ).finally(() => (this.showCurrentLocation = true));
      } else {
        this.showCurrentLocation = true;
      }
    });
    this.selectedLocation = this.currentLocation.id;
    if (this.$route.name === "price-groups-edit") {
      this.isModifyAblePriceGroup = this.hasPermission(
        policyList.modifyPriceGroup
      );
    }
  }
  protected async mounted() {
    this.isOnlyPriceGroup = this.$route.name!.includes("price-groups")
      ? true
      : false;
    if (this.$route.name === "price-groups-edit") {
      this.loading = true;
      const priceGroup = await priceGroupService.getPriceGroupById(
        this.$route.params.id
      );
      this.model = priceGroup;
      this.priceGroupId = Number(this.model.pricing_group_id);
      this.payloadObj = {
        source: "PRICING_GROUP",
        price_points: [
          {
            location_id: Number(this.selectedLocation),
            code: `PRICING_GROUP_${
              this.model.pricing_group_id
                ? this.model.pricing_group_id
                : this.priceGroupId
            }`,
            is_active: true,
            // @ts-ignore
            rules: this.model.rules
          }
        ]
      };
      this.loading = false;
    }
    if (this.$route.name === "price-groups-add") {
      this.loading = true;
      this.payloadObj = {
        source: "PRICING_GROUP",
        price_points: [
          {
            location_id: Number(this.selectedLocation),
            code: `PRICING_GROUP_${this.model.pricing_group_id}`,
            is_active: true,
            rules: []
          }
        ],
        price_group: this.model
      };
      this.loading = false;
    }

    const sharingData = await sharingService.getSharing();

    Object.entries(sharingData!.entities).forEach(entity => {
      const property = entity[0].toLowerCase();
      if (property === "price_group") {
        this.currentLocation.permission_locations = true;
        this.priceGroupSharing = true;
      }
    });
    this.setPageNav({
      title: "price_groups.title",
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            vuetifyProps: () => ({
              fab: true,
              small: true
            }),
            // visibleCondition: () => this.isModifyAblePriceGroup,
            action:
              this.$route.name === "price-groups-edit" ? this.update : this.save
          },
          {
            icon: "fal fa-times",
            action: this.cancel
          }
        ]
      }
    });
  }
}
